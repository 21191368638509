import { Dispatch, SetStateAction } from "react";
import "./WorkButtonStyles.css";

interface IStartStopProps {
  progress: number;
  active: boolean;
  triggerStartStopButton: Dispatch<SetStateAction<boolean>>;
  isDisabled: boolean;
}

export default function StartStopWorkButton(props: IStartStopProps) {
  const styles = {
    disable: {
      pointerEvents: "none" as const,
      opacity: 0.8,
    },
  };

  return (
    <div
      className="wrapperButton"
      style={props.isDisabled ? styles.disable : undefined}
    >
      <div className="skill">
        <div className="outer">
          <div className="inner">
            <div className={`playButton ${props.active && "active"}`}>
              <div className="playButtonBackground"></div>
              <div className="playButtonIcon">
                <div className="buttonPart buttonPartLeft"></div>
                <div className="buttonPart buttonPartRight"></div>
              </div>
              <div
                onClick={() =>
                  !props.isDisabled &&
                  props.triggerStartStopButton(!props.active)
                }
                className="buttonPointer"
              ></div>
            </div>
          </div>
        </div>
        <svg
          className="startStopSvg"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="200px"
          height="200px"
          stroke="url(#GradientColor)"
          strokeDashoffset={565 - (props.progress * 565) / 100}
        >
          <defs>
            <linearGradient id="GradientColor">
              <stop offset="0%" stop-color="#5B5FC7" />
              <stop offset="100%" stop-color="#79C75B" />
            </linearGradient>
          </defs>
          <circle cx="100" cy="100" r="90" stroke-linecap="round" />
        </svg>
      </div>
    </div>
  );
}
