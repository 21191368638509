import styles from "./EmplyeesListStyles";
import {
  useScrollbarWidth,
  useFluent,
  makeStyles,
  Button,
} from "@fluentui/react-components";
import * as React from "react";
import { FixedSizeList as List } from "react-window";
import {
  TableBody,
  TableRow,
  Table,
  TableHeaderCell,
  createTableColumn,
  useTableFeatures,
  TableRowData as RowStateBase,
} from "@fluentui/react-components/unstable";
import { WorktimeWithinDayListRow } from "../businessComponenets/";
import {
  IWorktimeWithinDayProps,
  IWorktimeWithinDayTabRow,
} from "../../services/types/table";
import { AppLoader } from "../common";
import { useParams } from "react-router-dom";
import { IGetWorktimeWithinDayOptions } from "../../services/types/worktimeHistory";
import { useEffect, useState } from "react";
import WorktimeWithinDayFilters from "./WorktimeWithinDayFilters";
import InfiniteLoader from "react-window-infinite-loader";

const columns = [
  createTableColumn<IWorktimeWithinDayTabRow>({
    columnId: "worktimeBeginning",
  }),
  createTableColumn<IWorktimeWithinDayTabRow>({
    columnId: "worktimeFinish",
  }),
  createTableColumn<IWorktimeWithinDayTabRow>({
    columnId: "worktimeSpanFormatted",
  }),
  createTableColumn<IWorktimeWithinDayTabRow>({
    columnId: "Actions",
  })
];

const useStyles = makeStyles({
  tableCell: {
    minWidth: "126px"
  },
  rightAlingment: {
    marginLeft: "auto"
  }
})

const WorktimeWithinDayList: React.FC<IWorktimeWithinDayProps> = ({
  items,
  isLoading,
  getMoreItems,
  WorktimeDate,
  UserId
}) => {

  const { targetDocument } = useFluent();
  const { id } = useParams<{ id: string }>();
  const scrollbarWidth = useScrollbarWidth({ targetDocument });
  const classes = useStyles();
  const [userIdentifier, setUserIdentifier] = useState<string | undefined>(undefined);
  const [date, setDate] = useState<string | undefined>(undefined);

  const {
    getRows,
    selection: { isRowSelected },
  } = useTableFeatures(
    {
      columns,
      items,
    }
  );

  interface TableRowData extends RowStateBase<IWorktimeWithinDayTabRow> {
    appearance: "brand" | "none";
    getWorktimeWithinDay: () => void
  }

  const setWorktimeOptions = (worktimeWithinDayOptions: IGetWorktimeWithinDayOptions) => {
    if (date && userIdentifier) {
      worktimeWithinDayOptions.date = date;
      worktimeWithinDayOptions.userId = userIdentifier;
    }
    
    if (id && !(date || userIdentifier)) worktimeWithinDayOptions.id = id;
    return worktimeWithinDayOptions;
  }

  const getMoreItemsWithOptions = (userIdentifier?: string, date?: string) => {
    let worktimeWithinDayOptions: IGetWorktimeWithinDayOptions = {
      stopIndex: 0,
      reload: true,
    }

    if (userIdentifier && date){
       worktimeWithinDayOptions.userId = userIdentifier;
       worktimeWithinDayOptions.date = date;
    }  else {
      worktimeWithinDayOptions = setWorktimeOptions(worktimeWithinDayOptions);
    }

    getMoreItems(worktimeWithinDayOptions);
}

  const rows: TableRowData[] = getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      appearance: selected ? ("brand" as const) : ("none" as const),
      getWorktimeWithinDay: () => {
        items.length = 0;
        getMoreItemsWithOptions();
      },
    };
  });

  const isItemLoaded = () => false;

  const loadMoreItems = (_: number, stopIndex: number) => {
    let worktimeWithinDayOptions: IGetWorktimeWithinDayOptions = {
      stopIndex: stopIndex,
      reload: false,
    }
    
    worktimeWithinDayOptions = setWorktimeOptions(worktimeWithinDayOptions);
    getMoreItems(worktimeWithinDayOptions);
  };


  const reloadTableContents = (rowIndex: number) => {
    if (rows[rowIndex]) {
      rows[rowIndex].getWorktimeWithinDay();
    }
  };

  useEffect(() => {
    getMoreItemsWithOptions();
  }, [id]);

  const getWorktimeWithinDay = (userId?: string, worktimeDate?: string) => {
    setUserIdentifier(userId);
    setDate(worktimeDate);
    getMoreItemsWithOptions(userId, worktimeDate);
  }

  const goBack = () => {
    window.history.back();
  }

  return (
    <div>
      <div>
        <div style={styles.assignmentsTableHeader}>
          <Button
              appearance="primary"
              onClick={goBack}
              style={styles.buttonBack}
              className={classes.rightAlingment}
          >
              Powrót
          </Button>
          <div style={styles.tableHeaderWrapper}>
            <WorktimeWithinDayFilters
              initialValues={{ date: WorktimeDate, employeeId: UserId }}
              getWorktimesWithinDay={getWorktimeWithinDay}
            />
          </div>
        </div>
      </div>
      <div className="main-table-container">
        <Table
          noNativeElements
          aria-label="Table with selection"
          aria-rowcount={rows.length}
          style={styles.table}
        >
          <TableBody className="tableBody">
            <TableRow aria-rowindex={1}>
              <TableHeaderCell className={classes.tableCell}>Rozpoczęcie okresu pracy</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Zakończenie okresu pracy</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Czas pracy</TableHeaderCell>
              <TableHeaderCell className={classes.tableCell}>Akcje na czasie pracy</TableHeaderCell>
              <div
                role="presentation"
                style={{
                  width: scrollbarWidth,
                }}
              />
            </TableRow>
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={items.length}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={350}
                  itemCount={items.length}
                  itemSize={45}
                  width="100%"
                  itemData={rows}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {({ index, style, data }) => (
                    <WorktimeWithinDayListRow
                      index={index}
                      style={style}
                      data={data}
                      reload={() => {
                        reloadTableContents(index);
                      }}
                    />
                  )}
                </List>
              )}
            </InfiniteLoader>
            {isLoading && <AppLoader />}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
export default WorktimeWithinDayList;