import { CenteredLayout } from "../../../components/layouts";
import useWorktimeHistory from "../../../hooks/useWorktimeHistory";
import { Card, CardPreview } from "@fluentui/react-components/unstable";
import AdminNavbar from "../../../components/layouts/AdminNavbar";
import PageTitle from "../../../components/common/PageTitle";
import WorktimeHistoryList from "../../../components/layouts/WorktimesHistoryList";

const WorktimeHistory = () => {
  const { worktimes, getWorktimeHistory, isLoading } =  useWorktimeHistory();

  return (
    <>
      <AdminNavbar />
      <CenteredLayout>
        <PageTitle title="Historia czasu pracy" />
        <div className='table-container'>
          <Card>
            <CardPreview>
              <WorktimeHistoryList
                tableHeadTxt="Dane dotyczące daty i godziny rozpoczęcia oraz zakończenia pracy"
                items={worktimes}
                getMoreItems={getWorktimeHistory}
                isLoading={isLoading}
              />
            </CardPreview>
          </Card>
        </div>
      </CenteredLayout>
    </>
  );
};

export default WorktimeHistory;
