import "./ActivityStatusBarStyles.css";
import { Input } from "@fluentui/react-components";
import { Dispatch, SetStateAction } from "react";

const ActivityStatusBar = (props: {
  availableCount: number;
  unavailableCount: number;
  onSearchTextChange: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="status-bar">
      <div className="status">
        <span className="dot green"></span>
        <span className="label">
          Dostępni: <span id="available-count">{props.availableCount ? props.availableCount : 0}</span>
        </span>
      </div>
      <div className="status">
        <span className="dot red"></span>
        <span className="label">
          Nieobecni: <span id="unavailable-count">{props.unavailableCount ? props.unavailableCount : 0}</span>
        </span>
      </div>
      <Input
        onChange={(e) => props.onSearchTextChange(e.target.value)}
        placeholder="Szukaj..."
        className="activityStatusInput"
      />
    </div>
  );
};

export default ActivityStatusBar;
