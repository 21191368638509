import { makeStyles } from '@fluentui/react-components';
import {
  TableCell,
  TableCellLayout,
  TableRow,
  TableRowData as RowStateBase,
} from '@fluentui/react-components/unstable';
import { ListChildComponentProps } from 'react-window';
import dateFormatService from '../../services/dateFormat.service';
import { IWorktimeHistoryTabRow } from '../../services/types/table';

interface ReactWindowRenderFnProps extends ListChildComponentProps {
  data: TableRowData[];
}

interface TableRowData extends RowStateBase<IWorktimeHistoryTabRow> {
  onClick: (e: React.MouseEvent) => void;
  selected: boolean;
  appearance: 'brand' | 'none';
  getWorktimeHistory: () => void
}

const useStyles = makeStyles({
  tableCell: {
    minWidth: "126px"
  },
})

const WorktimeHistoryListRow = ({
  index,
  style,
  data,
}: ReactWindowRenderFnProps) => {

  const { item, appearance, onClick } = data[index];
  const classes = useStyles();

  return (
    <>
      <TableRow
        aria-rowindex={index + 2}
        style={style}
        key={item.worktimeStartId}        
        onClick={onClick}
        appearance={appearance}
      >
        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            `${item.lastName} ${item.firstName}`
          }</TableCellLayout>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            dateFormatService.formatDateToTime(item?.worktimeBeginning)
          }</TableCellLayout>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            item?.worktimeFinish === null  
            ? 'W trakcie'
            : dateFormatService.formatDateToTime(item?.worktimeFinish)
          }</TableCellLayout>
        </TableCell>

        <TableCell className={classes.tableCell}>
          <TableCellLayout>{
            item?.totalWorktimeFormatted
          }</TableCellLayout>
        </TableCell>
      </TableRow>
    </>
  );
};

export default WorktimeHistoryListRow;