import { CenteredLayout } from "../../../components/layouts";
import { Card, CardPreview } from "@fluentui/react-components/unstable";
import useWorktimeHistory from "../../../hooks/useWorktimeHistory";
import AdminNavbar from "../../../components/layouts/AdminNavbar";
import PageTitle from "../../../components/common/PageTitle";
import WorktimeWithinDayList from "../../../components/layouts/WorktimeWithinDayList";

const WorktimeHistoryWithinDay = () => {
  const { 
    withinDayWorktime, 
    getWorktimesWithinDay, 
    isLoading, 
    userWorktimePageData 
  } =  useWorktimeHistory();

  return (
    <>
      <AdminNavbar />
      <CenteredLayout >
        <PageTitle title="Szczegółowa historia czasu pracy" />
        <div className='table-container'>
          <Card>
            <CardPreview >
              <WorktimeWithinDayList
                UserId={userWorktimePageData.UserId}
                WorktimeDate={userWorktimePageData.Date}
                items={withinDayWorktime}
                isLoading={isLoading}
                getMoreItems={getWorktimesWithinDay}
              />
            </CardPreview>
          </Card>
        </div>
      </CenteredLayout>
    </>
  );
};

export default WorktimeHistoryWithinDay;
