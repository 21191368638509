import { useCallback, useEffect, useState } from "react";
import {
  CreateWorktimeAsync,
  GetWorktimeAsync,
  PatchWorktimeAsync,
  Worktime,
} from "../services/worktime.service";
import timeConverter from "../services/dataTime.service";
import toast from "react-hot-toast";
import { mobileWidth } from "../services/Helpers/HelperService";

const useWorktime = () => {
  const [active, setActive] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [worktime, setWorktime] = useState<Worktime>({} as Worktime);

  useEffect(() => {
    setIsLoading(true);
    fetchWorktime();

    const interval = setInterval(() => {
      fetchWorktime();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const triggerButtonState = useCallback(async () => {
    if (!active) {
      setIsLoading(true);
      await CreateWorktimeAsync();
      await GetWorktimeAsync({ requestDate: new Date() })
        .then((worktime: Worktime) => {
          setWorktime(worktime);
          setActive(true);
          toast.success("Rozpoczęto pracę", {
            position:
              window.innerWidth < mobileWidth ? "top-center" : "top-right",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (active) {
      setIsLoading(true);
      await PatchWorktimeAsync();
      await GetWorktimeAsync({ requestDate: new Date() })
        .then((worktime: Worktime) => {
          setWorktime(worktime);
          setActive(false);
          toast.error("Zakończono pracę", {
            position:
              window.innerWidth < mobileWidth ? "top-center" : "top-right",
            icon: "👋",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [active]);

  const calculateProgress = (
    currentWorktime: number,
    eightHoursInMins: number = 480
  ): number => {
    return (currentWorktime / eightHoursInMins) * 100;
  };

  const fetchWorktime = useCallback(async () => {
    await GetWorktimeAsync({ requestDate: new Date() })
      .then((worktime: Worktime) => {
        setWorktime(worktime);
        setActive(worktime.isActive);
        const currentWorktime: number | undefined =
          timeConverter.convertStringToMin(worktime.totalWorktimeFormatted);

        if (currentWorktime !== undefined) {
          const worktimeInPercents = calculateProgress(currentWorktime);
          setProgress(worktimeInPercents);
        }
      })
      .finally(() => {
        setIsLoading(false);
        return {
          active,
          progress,
          worktime,
          isLoading,
          triggerButtonState,
        };
      });
  }, []);

  return {
    active,
    progress,
    worktime,
    isLoading,
    triggerButtonState,
  };
};

export default useWorktime;
