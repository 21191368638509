import { useEffect, useMemo } from "react";
import { UserActivity } from "../../../../services/worktime.service";
import ActivityStatusCard from "../ActivityStatusCard/ActivityStatusCard";
import { app } from "@microsoft/teams-js";

interface IActivityStatusList {
  users: UserActivity[];
  searchText: string;
}

const ActivityStatusList = (props: IActivityStatusList) => {
  useEffect(() => {
    app.initialize()
  }, []);

  const filteredUsers = useMemo(() => {
    return props.users
      .filter((user) => {
        return props.searchText.toLocaleLowerCase() === ""
          ? user
          : (user.firstName + ' ' + user.lastName).toLocaleLowerCase().includes(props.searchText.toLocaleLowerCase()) ||
            (user.lastName + ' ' + user.firstName).toLocaleLowerCase().includes(props.searchText.toLocaleLowerCase());
      })
      .map((user, index) => (
        <ActivityStatusCard key={`user-activity-${index}`} user={user} />
      ));
  }, [props.searchText, props.users]);

  return <>{filteredUsers}</>;
};

export default ActivityStatusList;
