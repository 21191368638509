import { useContext, useState } from "react";
import { Button, makeStyles } from "@fluentui/react-components";
import { Navigation24Filled } from '@fluentui/react-icons';
import { AppLink } from "../common";
import UserContext from "../../modules/AppRouter/UserContext";
import LinkMenu from "./LinkMenu";

const useStyles = makeStyles({
  container: {
    paddingInline: "3.5% 3.5%",
    position: "absolute",
    top: "0",
    backgroundColor: "white",
    width: "93%",
    zIndex: "9",
  },
  iconButton: {
    cursor: 'pointer',
    paddingBlock: '8px',
    backgroundColor: '#4b4e8d', 
    color: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBlock: '5px',
    float: 'right',
  },
  menu: {
    boxShadow: '0 0px 6px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '10',
    width: '100%',
  },
});

const isPhoneScreen = () => window.matchMedia("(max-width: 768px)").matches;

const adminLinkProps = [
  { to: "worktime", text: "Czas pracy" },
  { to: "admin/manage-employees", text: "Pracownicy" },
  { to: "admin/manage-assigments", text: "Zlecenia" },
  { to: "admin/raports", text: "Raporty" },
  { to: "admin/registered-time-history", text: "Historia czasu pracy" },
  { to: "collected-time-history", text: "Historia odebranych nadgodzin" },
  { to: "leavings-history", text: "Historia wyjść" },
  { to: "quick-start", text: "Instrukcja" },
];

const employeeLinkProps = [
  { to: "worktime", text: "Czas pracy" },
  { to: "employee", text: "Nadgodziny" },
  { to: "quick-start", text: "Instrukcja" },
];

const AdminNavbar: React.FC = () => {
  const styles = useStyles();
  const userData = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const isAdmin = userData.data.role === "Admin";
  const links = isAdmin ? adminLinkProps : employeeLinkProps;

  const handleIconClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {userData.isAssigned && (
        <div className={styles.container}>
          {isPhoneScreen() && links.length > 3 ? (
            <>
              <Button className={styles.iconButton} onClick={handleIconClick}>
                <Navigation24Filled />
              </Button>
              {menuOpen && <div className={styles.menu}>{
                links.map(link => <AppLink to={link.to} text={link.text} />)
              }</div>}
            </>
          ) : (
            <LinkMenu>{                
              links.map(link => <AppLink to={link.to} text={link.text} />)
            }</LinkMenu>
          )}
        </div>
      )}
    </>
  );
};

export default AdminNavbar;
