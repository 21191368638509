import { Button, makeStyles } from "@fluentui/react-components";
import { Card, CardPreview } from "@fluentui/react-components/unstable";
import { useState } from "react";
import {
  AddAssignmentDialog,
  AssignmentsList,
  CenteredLayout,
  OvertimeStatusCard,
} from "../../../components/layouts";
import useAsignments from "../../../hooks/useAsignments";
import { AppLink } from "../../../components/common";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
    marginTop: "4rem",
  },
  instructionBtn: {
    zIndex: "1",
    height: "2rem",
    marginInlineStart: "auto",
    marginInlineEnd: "1rem",
    width: "8rem",
  },
});

const ManageOvertime = () => {
  const styles = useStyles();
  const { assignments, getAssignments, isLoading, setAssignments } =    useAsignments();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <CenteredLayout>
        <AddAssignmentDialog
          open={openDialog}
          setOpen={setOpenDialog}
          getAssignments={getAssignments}
          setAssignments={setAssignments}
        />
        <div className={styles.container}>
        <Button className={styles.instructionBtn}>
        <AppLink to="quick-start" text="Instrukcja" />
      </Button>
          <OvertimeStatusCard />
          <Card>
            <CardPreview>
              <AssignmentsList
                tableHeadTxt="Wnioski i zlecenia"
                items={assignments}
                getMoreUsers={getAssignments}
                isLoading={isLoading}
                setOpendialog={setOpenDialog}
              />
            </CardPreview>
          </Card>
        </div>
      </CenteredLayout>
    </>
  );
};

export default ManageOvertime;
