import axiosInstance from "../utils/axios";
import apiService from "./api.service";
import {
  IFiltersData,
  IWorktimeWithinDayResponse,
  IWorktimeHistoryResponse,
} from "./types/worktimeHistory";
import { localStorageAccessorEnum} from "../utils/enums";
import { IModificationLogDetails } from "./types/table";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api/work-times`;

class WorktimeHistory {
  async getWorktimeHistory(skip: number, filters?: IFiltersData) {
    const rows = 10;
    const params = [];
    const {employeeId, date, activityStatus} = filters ?? {};
    
    if ( employeeId ) params.push({key: 'employeeId', value: employeeId});
    if ( date ) params.push({key: 'date', value: date});
    if ( activityStatus !== undefined ) params.push({key: 'isActive', value: (activityStatus == 'Active' ? true : false).toString()});

    let path = `${pathPrefix}/history?skip=${skip}&rows=${rows}`;
    if (params.length > 0) {
      path = apiService.setPathParams(path, params);
    }

    const response = await axiosInstance
      .get<IWorktimeHistoryResponse>(path.toString());
    return await Promise.resolve(response.data);
  }

  async getWorktimesWithinDay(skip: number, worktimeId?: string, userId?: string, date?: string) {
    const rows = 10;
    const params = [];

    if ( worktimeId ) params.push({key: 'id', value: worktimeId});
    if ( userId ) params.push({key: 'userId', value: userId});
    if ( date ) params.push({key: 'date', value: date});

    let path = `${pathPrefix}/within-day?skip=${skip}&rows=${rows}`;
    if (params.length > 0) {
      path = apiService.setPathParams(path, params);
    }

    const response = await axiosInstance
      .get<IWorktimeWithinDayResponse>(path.toString());
    return await Promise.resolve(response.data);
  }

  async editWorktime(worktimeId: string, worktimeBeginning: string, worktimeFinish: string | null, reasonForEdit: string) {
    const params = [];
    if ( worktimeId ) params.push({key: 'worktimeId', value: worktimeId});
    if ( worktimeBeginning ) params.push({key: 'worktimeBeginning', value: worktimeBeginning});
    if ( worktimeFinish ) params.push({key: 'worktimeFinish', value: worktimeFinish});
    if ( reasonForEdit ) params.push({key: 'reasonForEdit', value: reasonForEdit});

    let path = `${pathPrefix}/within-day`;

    if (params.length > 0) {
      path = apiService.setPathParams(path, params);
    }
    
    const response = await axiosInstance
      .put<Number>(path.toString().replace('&', '?'));
    return await Promise.resolve(response.data);
  }

  async getModifiedWorktime(worktimeId: string) {
    const path = `${pathPrefix}/${worktimeId}/modified-worktime`;

    const response = await axiosInstance
      .get<IModificationLogDetails[]>(path.toString());
    return await Promise.resolve(response.data);
  }

  getStoredFilters(): IFiltersData {
    const storedFilters = localStorage.getItem(localStorageAccessorEnum.Filters);
    return storedFilters ? JSON.parse(storedFilters) : undefined;
  }
}

const worktimeHistory = new WorktimeHistory();
export default worktimeHistory;